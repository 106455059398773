import React, { FC } from 'react';
import './PrivacyPolicy.scss';

interface PrivacyPolicyProps {}

const PrivacyPolicy: FC<PrivacyPolicyProps> = () => (
  <div className="PrivacyPolicy" data-testid="PrivacyPolicy">
    <div className='pp-block'>
      <h1>Privacy Policy</h1>
      <p><strong>Effective Date:</strong> January 04, 2025</p>

      <p>We respect your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, and safeguard data when you use our website. By accessing and using our website, you agree to the terms outlined in this policy.</p>

      <h2>Information We Collect</h2>
      <p>We do not require user registration or collect any personally identifiable information (PII). However, we may collect certain non-personal data for the purposes of improving your experience and tracking progress, as described below:</p>
      <ul>
          <li>
              <strong>Local Storage Data:</strong>
              <p>We store data, such as user scores and progress, directly in your web browser’s local storage. This data is accessible only to you and is used to enhance your learning experience by keeping track of your progress within the website.</p>
          </li>
          <li>
              <strong>Backend Data:</strong>
              <p>Limited data related to usage metrics, such as page visits or interactions with our tools, may be collected via our backend system to help us monitor website performance and improve functionality. This data is aggregated and does not include any personally identifiable information.</p>
          </li>
      </ul>

      <h2>How We Use the Information</h2>
      <p>The information collected is used solely for the following purposes:</p>
      <ul>
          <li>To provide and improve the features and functionality of the website.</li>
          <li>To track and display your progress within the app, such as quiz scores or completed activities.</li>
          <li>To analyze website performance and enhance the overall user experience.</li>
      </ul>

      <h2>Data Sharing</h2>
      <p>We do not sell, trade, or share your data with third parties. Any information collected is used strictly for the purposes outlined above and remains within the confines of our systems.</p>

      <h2>Your Control and Choices</h2>
      <p>Since the data is stored in your browser’s local storage, you have full control over it. You can delete your progress and scores at any time by clearing your browser’s local storage. Note that doing so will reset your progress within the website.</p>

      <h2>Data Security</h2>
      <p>We take appropriate measures to ensure the security of any data collected. However, please note that no system is entirely immune to potential security risks, and we encourage you to use secure connections when accessing our website.</p>

      <h2>Children’s Privacy</h2>
      <p>Our website is designed for users of all ages, but it is not specifically directed at children under the age of 13. We do not knowingly collect personal information from children.</p>

      <h2>Changes to This Privacy Policy</h2>
      <p>We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Updates will be posted on this page with the revised effective date.</p>

      <h2>Contact Us</h2>
      <p>If you have any questions or concerns about this Privacy Policy, please feel free to contact us at:</p>
      <p><a href="mailto:everyday.new.language@gmail.com">everyday.new.language@gmail.com</a></p>
    </div>
  </div>
);

export default PrivacyPolicy;
