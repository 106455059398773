import React, { FC, useState, useRef, useEffect } from 'react';
import './IrregularVerbs.scss';
import Table from 'react-bootstrap/Table';
import irregularVerbs from '../../assets/static/irregular-verbs.json';
import { Verb } from '../../data/data';
import SlotMachine from '../SlotMachine/SlotMachine';
import { Helmet } from 'react-helmet';

interface IrregularVerbsProps {}

const IrregularVerbs: FC<IrregularVerbsProps> = () => {
  const verbs: Verb[] = irregularVerbs;
  const [pastValue, setPastValue] = useState<string>("");
  const [participleValue, setParticipleValue] = useState<string>("");
  const slotMachineRef = useRef<{ startSpin: () => void; getCurrentWord: () => Verb | undefined } | null>(null);
  const [inputPastColor, setInputPastColor] = useState<string>("");
  const [inputParticipleColor, setInputParticipleColor] = useState<string>("");
  const [inputPastEnabled, setInputPastEnabled] = useState<boolean>(false);
  const [inputParticipleEnabled, setInputParticipleEnabled] = useState<boolean>(false);
  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);

  const triggerSpin = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setPastValue("");
    setParticipleValue("");
    setInputPastEnabled(false);
    setInputParticipleEnabled(false);
    setInputPastColor("black");
    setInputParticipleColor("black");
    if (slotMachineRef.current) {
      slotMachineRef.current.startSpin();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === 'past') setPastValue(value);
    if (name === 'participle') setParticipleValue(value);
  };

  const handleCheck = () => {
    const word = slotMachineRef.current?.getCurrentWord();
    setPastValue(getPast(word?.past_simple || ""));
    setParticipleValue(getParticiple(word?.past_participle || ""));
    setInputPastEnabled(true);
    setInputParticipleEnabled(true);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const getPast = (word: string) => {
    if (pastValue !== "" && word.includes(pastValue.trim())) {
      setInputPastColor("green");
      return pastValue;
    }
    setInputPastColor("red");
    return word + " ✔ | ✘ " + pastValue;
  }

  const getParticiple = (word: string) => {
    if (participleValue !== "" && word.includes(participleValue.trim())) {
      setInputParticipleColor("green");
      return participleValue;
    }
    setInputParticipleColor("red");
    return word + " ✔ | ✘ " + participleValue;
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();

      if (pastValue === "") {
        input1Ref.current?.focus();
        return;
      }
      if (participleValue === "") {
        input2Ref.current?.focus();
        return;
      }
      handleCheck();
    }
  };

  useEffect(() => {
    triggerSpin();
  }, []);
  
  return (
    <div className="IrregularVerbs" data-testid="IrregularVerbs">
      <Helmet>
        <title>Everyday English | Irregular Verbs</title>
      </Helmet>
      <div className='fs-6 p-3 irregular-title'>- Test your knowledge of Irregular verbs -</div>
      <div className='irregural-quiz'>
        <div className='irregular-spinner'>
          <div className='irregular-base'>
            <div className='fs-4'>Base Form</div>
            <SlotMachine ref={slotMachineRef} words={verbs} spinDuration={1000} />
          </div>
          <div className='irregular-simple'>
            <div className='fs-4'>Past Simple</div>
            <input
              id="text-input"
              type="text"
              name="past"
              value={pastValue}
              onChange={handleInputChange}
              style={{ color: inputPastColor }}
              disabled={inputPastEnabled}
              ref={input1Ref}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
          <div className='irregular-participle'>
            <div className='fs-4'>Past Participle</div>
            <input
              id="text-input"
              type="text"
              name="participle"
              value={participleValue}
              onChange={handleInputChange}
              style={{ color: inputParticipleColor }}
              disabled={inputParticipleEnabled}
              ref={input2Ref}
              onKeyDown={(e) => handleKeyDown(e)}
            />
          </div>
        </div>
        <div className='irregular-panel'>
          <button onClick={triggerSpin} className='button-orange'>
            Spin
          </button>
          <button onClick={handleCheck} className='button-green'>
            Check
          </button>
        </div>
      </div>
      <Table striped bordered className='irregular-table' size="sm">
        <thead>
          <tr>
            <th>Base Form</th>
            <th>Past Simple</th>
            <th>Past Participle</th>
          </tr>
        </thead>
        <tbody>
          {verbs.map((verb, index) => (
            <tr key={index}>
              <td>{verb.base_form}</td>
              <td>{verb.past_simple}</td>
              <td>{verb.past_participle}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className='about-irregular-verbs'>
        <h1>How to Learn Irregular Verbs</h1>
        <p>Irregular verbs are a core component of English, appearing frequently in both spoken and written language. 
          Unlike regular verbs, which simply add “-ed” for past forms, irregular verbs can vary significantly. 
          This means they must be memorized and practiced consistently.
        </p>
        <p>
          At the top, you have the option to <strong>Spin</strong> the base form of the verb. Then, you can complete the past simple 
          and past participle forms. After you finish, you can check your answers by pressing the <strong>Check</strong> button.
        </p>
        <p>
          Our app is designed to make this process as efficient and enjoyable as possible. By engaging with the verbs regularly, 
          you’ll gradually internalize their patterns and forms. Remember, consistency is key—daily practice will yield significant 
          results over time.
        </p>
        <p>
          We believe that our tool can help you in your learning journey. We’re constantly striving to improve the app and tailor it to your needs. 
          If you have suggestions, feature requests, or ideas for enhancements, we’d love to hear from you. Your feedback plays a vital role in shaping 
          our tool and ensuring it meets the needs of learners like you.
          Additionally, we’ve prepared some useful tips below to help you stay consistent and make progress in learning irregular verbs.
        </p>
        <p>
        Learning irregular verbs doesn’t have to be boring. With the right tools, simple methods, and regular practice, you can learn them quickly. 
        Start spinning, filling out exercises, and checking your answers. Your path to mastering irregular verbs starts now!
        </p>
      </div>
      <div className='about-irregular-verbs'>
        <h2>Tips to Learn Irregular Verbs and Stay Consistent</h2>

        <h3>Learning Tips</h3>
        <ul>
            <li><strong>Group Similar Verbs:</strong> Group verbs with similar patterns (e.g., "sing-sang-sung," "ring-rang-rung") to make them easier to remember.</li>
            <li><strong>Practice:</strong> Write the base form, past tense, and past participle</li>
            <li><strong>Use Mnemonics:</strong> Create fun sentences or stories to link irregular verbs to their forms (e.g., “The <i>king</i> <i>sang</i> a song and it was <i>sung</i> everywhere”).</li>
            <li><strong>Practice in Context:</strong> Write sentences using each form of the verb. For example:
                <ul>
                    <li>Today, I <i>go</i> to school.</li>
                    <li>Yesterday, I <i>went</i> to school.</li>
                    <li>I have <i>gone</i> to school many times.</li>
                </ul>
            </li>
            <li><strong>Learn with Songs or Rhymes:</strong> Many songs and poems use irregular verbs, and associating them with a melody can aid memory.</li>
        </ul>

        <h3>Staying Consistent</h3>
        <ul>
            <li><strong>Set a Daily Goal:</strong> Aim to learn and review 3-5 verbs daily. Small, consistent steps are effective.</li>
            <li><strong>Use a Verb Journal:</strong> Keep a notebook where you list verbs, sentences, and progress. Revisit it weekly.</li>
            <li><strong>Practice with a Friend:</strong> Quiz each other or create challenges to make learning fun and interactive.</li>
            <li><strong>Integrate Verbs into Daily Activities:</strong> Narrate your day using irregular verbs aloud or in writing: “I <i>ate</i> breakfast, then I <i>wrote</i> emails.”</li>
            <li><strong>Reward Yourself:</strong> Set milestones (e.g., 50 verbs learned) and reward your efforts to stay motivated.</li>
        </ul>
      </div>
    </div>
  );
};

export default IrregularVerbs;
