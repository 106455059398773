import React, { FC, useEffect, useState, useRef } from 'react';
import './Listening.scss';
import { getListening } from '../../data/openAI';
import ReactAudioPlayer from 'react-audio-player';
import { FaEyeSlash, FaEye } from "react-icons/fa"; 

interface ListeningProps {}

const Listening: FC<ListeningProps> = () => {
  const [listening, setListening] = useState<any>();
  const [value, setValue] = useState<string>("");
  const [checkedValue, setCheckedValue] = useState<string>("");
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getListening().then((result) => {
      setListening(result);
      setLoading(false);
    });
  }, []);

  if (isLoading)
    return (<div className='loading-words'><div style={{textAlign: "center"}}>Loading ...</div></div>)

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.target;
    setValue(value);
    adjustHeight();
  };

  const adjustHeight = () => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  const handleCheck = () => {
    setIsChecked(true);
    setCheckedValue(highlightIncorrectWords(listening.transcript, value));
  };

  const handleHide = () => {
    setIsHidden(!isHidden);
  };

  const handleClear = () => {
    setValue("");
    setCheckedValue("");
  };

  const handleNewStory = () => {
    setLoading(true);
    getListening().then((result) => {
      setListening(result);
      setLoading(false);
    });
    setCheckedValue("");
    setValue("");
    setIsChecked(false);
    setIsHidden(true);
  };

  function highlightIncorrectWords(referenceText: string, inputText: string): string {
    const stripNonAlphanumeric = (str: string) => str.replace(/[^a-zA-Z0-9]/g, '');

    let highlightedText = '';
    
    const referenceWords = referenceText.split(' ');
    const inputWords = inputText.split(' ');

    for (let i = 0; i < Math.max(referenceWords.length, inputWords.length); i++) {
        const referenceWord = referenceWords[i] || '';
        const inputWord = inputWords[i] || '';

        const cleanedReferenceWord = stripNonAlphanumeric(referenceWord);
        const cleanedInputWord = stripNonAlphanumeric(inputWord);

        if (cleanedReferenceWord !== cleanedInputWord) {
            highlightedText += `<span style="color: red">${inputWord}</span> `;
        } else {
            highlightedText += inputWord + ' ';
        }
    }

    return highlightedText.trim();
  }

  return (
    <div className="Listening">
      <div className='listening-container'>
        <h1 className='fs-2'>Rewrite the Story Exactly</h1>
        <div className='listening-audio'>
          <ReactAudioPlayer
            src={`data:audio/wav;base64,${listening?.audioData}`}
            controls
            controlsList='nodownload'
          />
          <button onClick={handleNewStory} className='button-orange'>
            Generate Story
          </button>
        </div>
        <textarea
          ref={textAreaRef}
          id="text-input"
          value={value}
          onChange={handleInputChange}
        />
        <div className='fs-4 listening-correct' dangerouslySetInnerHTML={{ __html: `<p>${checkedValue}</p>`}} />
        <div className='listening-panel'>
          <button onClick={handleCheck} className='button-green'>
            Check
          </button>
          <button onClick={handleClear} className='button-orange'>
            Clear
          </button>
          { isChecked ? 
            <button onClick={handleHide} className='button-hide'>
              {isHidden ? "Show" : "Hide"}
              {isHidden ? <FaEye className='ml-1' />: <FaEyeSlash className='ml-1' />}
            </button> 
            : null
          }
        </div>
        {!isHidden ? <p className='fs-5 correct-text'>{listening?.transcript}</p> : null}
      </div>
    </div>
  );
};

export default Listening;
