import React, { FC } from 'react';
import './Menu.scss';
import { useNavigate } from 'react-router-dom';

interface MenuProps {}

const Menu: FC<MenuProps> = () => {
  const navigate = useNavigate();

  function onQuizClick() {
    navigate("/quiz");
  }

  function onWordsClick() {
    navigate("/words");
  }

  function onIrregularClick() {
    navigate("/irregular");
  }

  function onListeningClick() {
    navigate("/listening/true-false");
  }

  return (
    <div className="main-menu" data-testid="Menu">
      <div className='menu-container'>
        <div className='quiz menu' onClick={onQuizClick}>
          <div className='text-menu'>Quiz</div>
        </div>
        <div className='irregular menu' onClick={onIrregularClick}>
          <div className='text-menu'>Irregular verbs</div>
        </div>
        <div className='listening menu' onClick={onListeningClick}>
          <div className='text-menu'>Listening</div>
        </div>
        <div className='word menu' onClick={onWordsClick}>
          <div className='text-menu'>Words</div>
        </div>
      </div>
      <div className='menu-content'>
        <h1>
          Improve Your English Every Day
        </h1>
        <section>
            <p>Welcome! If you're looking to make real progress with your English, you're in the right place. Whether you're practicing for work, travel, or everyday conversations, our tools and exercises are here to help you every step of the way.</p>
        </section>
        <section>
            <h2>Here’s How We Can Help You:</h2>
            <ul>
                <li><strong>Interactive Quizzes:</strong> Test your knowledge and discover where you can improve. These quick and fun quizzes are perfect for building confidence in using English every day.</li>
                <li><strong>Learn New Words:</strong> Expand your vocabulary with tools designed to help you learn English words easily and remember them longer.</li>
                <li><strong>Master Irregular Verbs:</strong> Don’t let irregular verbs hold you back! Practice and memorize them with our simple, effective tools so you can use them naturally in conversations.</li>
                <li><strong>Practice Listening:</strong> Develop your listening skills with real-life audio exercises. These activities are crafted to help you understand and respond confidently in everyday situations.</li>
            </ul>
        </section>
        <section>
            <h2>Why It Works:</h2>
            <p>Our platform combines daily practice with practical tools that focus on <em>real-world English</em>. From taking a quick <strong>everyday quiz</strong> to brushing up on tricky irregular verbs, each feature is designed to help you improve at your own pace and feel more comfortable using English in daily life.</p>
            <p>Whether you're preparing for a test, working on your conversational skills, or just looking to challenge yourself, these tools will guide you toward fluency step by step.</p>
        </section>
        <section>
            <h2>Start Learning Today</h2>
            <p>Ready to take your English to the next level? Start exploring and make <strong>everyday english</strong> part of your routine today!</p>
        </section>
      </div>
      <div className='feedback-container-menu' onClick={() => window.open("mailto:everyday.new.language@gmail.com?subject=Everryday English Feedback")}>
        I would appreciate your feedback - everyday.new.language@gmail.com
      </div>
    </div>
  )
};


export default Menu;
