import { QuizQuestion, QuestionEnglish } from "./data";
import axios from 'axios';

const baseUrl = process.env.REACT_APP_BASE_URL;

async function getData() {
  try {
    const response = await axios.get(baseUrl + '/public/questions');
    const parsedQuestion: QuizQuestion[] = JSON.parse(response.data.questionJson).questions;
    return parsedQuestion;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getAllData() {
  try {
    const response = await axios.get(baseUrl + '/public/questions/list');
    const questions: QuestionEnglish[] = response.data;
    const parsedQuestions: QuizQuestion[] = questions.flatMap(question => {
      try {
        const parsedData = JSON.parse(question.questionJson);
        return parsedData.questions || [];
      } catch (error) {
        return [];
      }
    });
    return parsedQuestions;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getUnitWords(level: number) {
  try {
    const response = await axios.get(baseUrl + '/public/words/unit?unit=' + level);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

async function getImage(id: number) {
  try {
    const url = `${baseUrl}/public/words/image/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
}

async function getSound(id: number) {
  try {
    const url = `${baseUrl}/public/words/sound/${id}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error;
  }
}

async function getListening() {
  try {
    const url = `${baseUrl}/public/listening`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching listening:', error);
    throw error;
  }
}

async function getListeningTF() {
  try {
    const url = `${baseUrl}/public/listening/tf`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching listening:', error);
    throw error;
  }
}

export {getData, getImage, getUnitWords, getSound, getListening, getAllData, getListeningTF};