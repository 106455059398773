import React, { FC } from 'react';
import './Footer.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ShareButton from '../ShareButton/ShareButton';

const Footer: FC = () => (
  <div className="footer">
    <Container>
      <Row>
        <hr className="text-dark" />
        <Col md={4} className="text-center text-md-left">
          <h5>About</h5>
          <ul className="list-unstyled">
            <li><a href="/about-us" className="text-dark">About Us</a></li>
            <li><a href="/contact-us" className="text-dark">Contact Us</a></li>
            <li><a href="/privacy-policy" className="text-dark">Privacy Policy</a></li>
          </ul>
        </Col>
        <Col md={4} className="text-center">
          <h5>Quick Links</h5>
          <ul className="list-unstyled">
            <li><a href="/" className="text-dark">Home</a></li>
            <li><a href="/quiz" className="text-dark">Quiz</a></li>
            <li><a href="/words" className="text-dark">Words</a></li>
          </ul>
        </Col>
        <Col md={4} className="text-center text-md-right">
          <h5>Follow Us</h5>
          <ul className="list-unstyled">
            <li><a href="https://x.com/Everyday_Eng__" className="text-dark">X</a></li>
            <li><a href="https://www.reddit.com/r/everydayenglish/" className="text-dark">Reddit</a></li>
            <li><ShareButton /></li>
          </ul>
        </Col>
      </Row>
      <hr className="text-dark" />
      <Row>
        <Col className="text-center fs-6">
          <p>&copy; 2025 everyday-english.org - All Rights Reserved.</p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default Footer;
