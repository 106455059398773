import React, { FC } from 'react';
import './AboutUs.scss';
import { Helmet } from 'react-helmet-async';

interface AboutUsProps {}

const AboutUs: FC<AboutUsProps> = () => (
  <div className="AboutUs" data-testid="AboutUs">
    <Helmet>
      <title>Everyday English | About Us</title>
    </Helmet>
    <div className='about-block'>
      <div>
        <h1>About Us</h1>
        <p><strong className='fs-4'>Welcome to Everyday-English!</strong> <br /></p>
        <p>
          At Everyday-English, our mission is to revolutionize language learning through engaging, interactive, 
          and gamified experiences. Whether you're just starting to learn a new language or looking to improve 
          your skills, we’re here to make the journey fun, interactive, and rewarding.
        </p>
        <p>
          Language learning has always been challenging for us, so we decided to create this website to help us 
          stay motivated and maintain consistency, which we believe is the key to success. We promise that with our tool, 
          you will significantly improve your language skills if you make it part of your daily routine. 
          We are constantly adding new features to the website and, with your feedback, improving the quality of the content.
        </p>
        <p>
          Everyday-English was founded with a simple idea: to make language learning accessible and enjoyable for everyone. 
          We realized that traditional methods often fail to maintain motivation, so we set out to create a platform where 
          users can <strong>learn by playing</strong>, and <strong>interacting</strong> with the content in ways that keep them hooked.
        </p>
        <p><strong className='fs-4'>Our Values</strong></p>
        <ul>
            <li><strong>Innovation:</strong> We continuously update our tools and content to provide the latest in educational technology.</li>
            <li><strong>Engagement:</strong> We believe learning should be exciting! Our interactive tools make language acquisition more dynamic and fun.</li>
            <li><strong>Accessibility:</strong> Our platform is designed to be accessible to learners of all levels, from beginners to advanced, and on all devices.</li>
        </ul>
        <p><strong className='fs-4'>Get Involved</strong></p>
        <p>
          We are always striving to improve and grow. Join our community by signing up, sharing your feedback, or following 
          us on social media. Together, we can make language learning a more enjoyable and fulfilling experience!
        </p>
      </div>
      <div>
        <h1>Get in Touch</h1>
        <p><strong onClick={() => window.open("mailto:everyday.new.language@gmail.com?subject=Everryday English Feedback")} className='cursor-pointer'>Email: </strong>everyday.new.language@gmail.com</p>
        <p className='cursor-pointer' onClick={() => window.open("https://x.com/Everyday_Eng__")}><strong>X: </strong>@Everyday_Eng__</p>
        <p className='cursor-pointer' onClick={() => window.open("https://www.reddit.com/r/everydayenglish/")}><strong>Reddit: </strong>r/everydayenglish</p>
      </div>
    </div>
  </div>
);

export default AboutUs;
