import React, { useState } from "react";
import { ListeningQuestion } from "../../data/data";

interface TrueFalseQuizProps {
  questions: ListeningQuestion[];
}

const TrueFalseQuiz: React.FC<TrueFalseQuizProps> = ({ questions }) => {
  const [selectedAnswers, setSelectedAnswers] = useState<(boolean | null)[]>(
    Array(questions.length).fill(null)
  );

  const handleAnswer = (index: number, answer: boolean) => {
    setSelectedAnswers((prevAnswers) => {
      const updatedAnswers = [...prevAnswers];
      updatedAnswers[index] = answer;
      return updatedAnswers;
    });
  };

  return (
    <div className="tf-container mt-4">
      <div className="question-tf-container">
        {questions.map((q, index) => (
          <div
            key={index}
            className="mb-5 p-4 border rounded"
          >
            <p className="mb-4 fs-4">{q.question}</p>
            <div className="tf-button-container">
              <button
                className={`btn fs-5 ${
                  selectedAnswers[index] === true
                    ? q.answer === true
                      ? "btn-success"
                      : "btn-danger"
                    : "btn-outline-dark"
                }`}
                onClick={() => handleAnswer(index, true)}
              >
                True
              </button>
              <button
                className={`btn fs-5 ${
                  selectedAnswers[index] === false
                    ? q.answer === false
                      ? "btn-success"
                      : "btn-danger"
                    : "btn-outline-dark"
                }`}
                onClick={() => handleAnswer(index, false)}
              >
                False
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrueFalseQuiz;