import React, { FC } from 'react';
import './SentenceWriting.scss';

interface SentenceWritingProps {}

const SentenceWriting: FC<SentenceWritingProps> = () => {

  return (
    <div className="SentenceWriting" data-testid="SentenceWriting">
      <p className='fs-1'></p>
      <input
        id="text-input"
        type="text"
        name="participle"
      />
      <div className='explanation'>
        <p className=''>
          explanation
        </p>
      </div>
    </div>
  );
};

export default SentenceWriting;
