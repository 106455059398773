import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import './SlotMachine.scss';
import { Verb } from '../../data/data';

interface SlotMachineProps {
  words: Verb[];
  spinDuration: number;
}

const SlotMachine = forwardRef(({ words, spinDuration }: SlotMachineProps, ref) => {
  const [spinning, setSpinning] = useState(false);
  const [currentWord, setCurrentWord] = useState<Verb | undefined>(undefined);

  useEffect(() => {
    if (spinning) {
      const interval = setInterval(() => {
        const randomWord = words[Math.floor(Math.random() * words.length)];
        setCurrentWord(randomWord);
      }, 100);

      setTimeout(() => {
        clearInterval(interval);
        setSpinning(false);
      }, spinDuration);

      return () => clearInterval(interval);
    }
  }, [spinning, words, spinDuration]);

  const startSpin = () => {
    if (!spinning) {
      setSpinning(true);
    }
  };

  // Expose methods to the parent via the ref
  useImperativeHandle(ref, () => ({
    startSpin,
    getCurrentWord: () => currentWord,
  }));

  return (
    <div>
      <div className="slot-container">
        <input
          id="text-input"
          type="text"
          value={currentWord?.base_form || ""}
          readOnly={true}
        />
      </div>
    </div>
  );
});

export default SlotMachine;