import React, { FC } from 'react';
import './ContactUs.scss';
import { Helmet } from 'react-helmet-async';

interface ContactUsProps {}

const ContactUs: FC<ContactUsProps> = () => (
  <div className="ContactUs" data-testid="ContactUs">
    <Helmet>
      <title>Everyday English | Contact Us</title>
    </Helmet>
    <div className='contact-block'>
      <div>
        <h1>Contact Us</h1>
        <p>We'd love to hear from you! Whether you have a question, feedback, or a suggestion, 
          feel free to reach out to us using the information below. We'll get back to you as soon as possible.
        </p>
      </div>
      <div>
        <h1>Get in Touch</h1>
        <p><strong onClick={() => window.open("mailto:everyday.new.language@gmail.com?subject=Everryday English Feedback")} className='cursor-pointer'>Email: </strong>everyday.new.language@gmail.com</p>
        <p className='cursor-pointer' onClick={() => window.open("https://x.com/Everyday_Eng__")}><strong>X: </strong>@Everyday_Eng__</p>
        <p className='cursor-pointer' onClick={() => window.open("https://www.reddit.com/r/everydayenglish/")}><strong>Reddit: </strong>r/everydayenglish</p>
      </div>
    </div>
  </div>
);

export default ContactUs;
