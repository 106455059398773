import React from 'react';
import { FiShare2 } from 'react-icons/fi';
import './ShareButton.scss';

const ShareButton: React.FC = () => {
  const handleShare = async (): Promise<void> => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Check this out!',
          text: 'I found this amazing website, take a look!',
          url: window.location.href,
        });
        console.log('Content shared successfully!');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert('Share feature is not supported in your browser.');
    }
  };

  return (
    <button onClick={handleShare} className='share-button'>
      <FiShare2 />
      Share
    </button>
  );
};

export default ShareButton;