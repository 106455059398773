import React, { FC, useEffect, useState } from 'react';
import './Main.scss';
import Question from '../Question/Question';
import { getAllData, getData } from '../../data/openAI';
import { QuizQuestion } from '../../data/data';

interface MainProps {}

const Main: FC<MainProps> = () => {
  const [quizData, setQuizData] = useState<QuizQuestion[]>([]);
  const [quizList, setQuizList] = useState<QuizQuestion[]>([]);
  const [isMore, setMore] = useState<boolean>(false);

  useEffect(() => {
    getData().then((result) => {
      setQuizData(result || []);
    });
    getAllData().then((result) => {
      setQuizList(result || []);
    });
  }, []);

  const handleMore = () => {
    setMore(true);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main" data-testid="Main">
      <div className='content'>
        <div className='ai-title-quiz fs-6'>The questions are generated with AI every day</div>
        {
          quizData?.map((option: any, index: number) => (<Question key={index} question={option} index={index + 1}/>))
        }
        {
          isMore ? quizList?.map((option: any, index: number) => (<Question key={index} question={option} index={index + 11}/>)) : null
        }
        { !isMore ? <div onClick={handleMore} className='button-orange text-center'>More Questions</div> : null}
      </div>
      <button
        onClick={handleScrollToTop}
        className='scroll-to-top-button'
      >
        ↑ Back to Top
      </button>
    </div>
  );
};

export default Main;
