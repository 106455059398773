import React, { FC, useEffect, useState } from 'react';
import './Listening.scss';
import { getListeningTF } from '../../data/openAI';
import ReactAudioPlayer from 'react-audio-player';
import { FaEyeSlash, FaEye } from "react-icons/fa"; 
import { ListeningQuestion } from '../../data/data';
import TrueFalseQuiz from './TrueFalseQuiz';
import { Helmet } from 'react-helmet';

interface ListeningProps {}

const ListeningTrueFalse: FC<ListeningProps> = () => {
  const [listening, setListening] = useState<any>();
  const [questions, setQuestions] = useState<ListeningQuestion[]>([]);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    getListeningTF().then((result) => {
      setListening(result);
      const questions: ListeningQuestion[] = JSON.parse(result.questions);
      setQuestions(questions);
      setLoading(false);
    });
  }, []);

  if (isLoading)
    return (<div className='loading-words'><div style={{textAlign: "center"}}>Loading ...</div></div>)

  const handleHide = () => {
    setIsHidden(!isHidden);
  };

  const handleNewStory = () => {
    setLoading(true);
    getListeningTF().then((result) => {
      setListening(result);
      const questions: ListeningQuestion[] = JSON.parse(result.questions);
      setQuestions(questions);
      setLoading(false);
    });
    setIsHidden(true);
  };

  return (
    <div className="Listening">
      <Helmet>
        <title>Everyday English | Story Listening | Listening Practice | Hearing Practice</title>
      </Helmet>
      <div className='listening-container'>
        <h1 className='fs-2'>True/False Quiz</h1>
        <div className='listening-audio'>
          <ReactAudioPlayer
            src={`data:audio/wav;base64,${listening?.audioData}`}
            controls
            controlsList='nodownload'
          />
          <button onClick={handleNewStory} className='button-orange'>
            New Story
          </button>
        </div>
        <TrueFalseQuiz questions={questions} />
        <div className='listening-panel'>
            <button onClick={handleHide} className='button-hide'>
              {isHidden ? "Show" : "Hide"}
              {isHidden ? <FaEye className='ml-1' />: <FaEyeSlash className='ml-1' />}
            </button>
        </div>
        {!isHidden ? <p className='fs-5 '>{listening?.transcript}</p> : null}
      </div>
      <div className='tf-info'>
        <h1>How True/False Questions in English Listening Can Help Improve Your Listening Skills</h1>
        <section>
          <p>Learning English requires more than just reading or writing, active listening is essential to mastering the language. One effective way to enhance your listening skills is through English story listening, which not only improves your ability to understand spoken English but also sharpens your attention to detail. This approach becomes even more powerful when combined with true/false questions that help you assess your comprehension.</p>

          <p>In this article, we’ll explore how English listening practice through audio stories and true/false questions can significantly boost your English language skills, focusing on English hearing practice and the importance of effective listening exercises.</p>
        </section>

        <section>
          <h2>Why True/False Questions Work for English Listening Practice</h2>
          <p>When you engage in English story listening, your goal is to understand the main ideas, supporting details, and subtle nuances in the spoken text. Listening comprehension can be tricky because it involves multiple skills, from recognizing words to interpreting meaning in context. However, using true/false questions after listening to a story provides an effective way to test and reinforce your understanding.</p>

          <h3>1. Active Listening Engagement</h3>
          <p>True/false questions require you to actively listen, not just passively hear the story. In order to correctly answer these questions, you need to focus on the key information provided in the audio story. This kind of active listening encourages better attention to details like tone, intonation, and word choice, all of which are crucial for language acquisition.</p>

          <h3>2. Immediate Feedback</h3>
          <p>One of the biggest benefits of using true/false questions is the immediate feedback they provide. After listening to an audio story and answering the questions, you can quickly check whether your answers are correct. This instant feedback helps you identify areas of improvement and gives you a clear understanding of your listening strengths and weaknesses.</p>

          <h3>3. Reinforcement of Vocabulary and Grammar</h3>
          <p>True/false questions often require you to recall specific details from the story, which reinforces the vocabulary and grammar you've encountered. By listening to audio stories and answering true/false questions, you are exposed to the language in context, which strengthens your ability to understand English in real-life situations.</p>

          <h3>4. Boosting Listening Comprehension</h3>
          <p>English hearing practice doesn’t only involve recognizing words; it also requires understanding the context. True/false questions help you focus on this comprehension aspect by prompting you to consider whether certain details are accurate. This is an essential skill for everyday conversations and advanced listening tests like the TOEFL or IELTS.</p>
        </section>

        <section>
          <h2>How Audio Stories Make English Listening Practice Fun</h2>
          <p>The beauty of using audio stories in your learning process is that they provide real, engaging contexts for language practice. Listening to a generated story not only helps you improve comprehension but also exposes you to natural sentence structures, cultural references, and conversational phrases. Whether the stories are short anecdotes or longer narratives, they provide valuable exposure to the way English is spoken in various contexts.</p>
        </section>

        <section>
          <h2>The Role of True/False Questions in Improving English Hearing Practice</h2>
          <p>When combined with true/false questions, audio stories can enhance your English hearing practice by sharpening your ability to pick out relevant details and understand spoken language at a deeper level. Listening comprehension improves through consistent practice, and answering questions after each listening session helps solidify your learning.</p>
        </section>

        <section>
          <h2>Conclusion</h2>
          <p>Incorporating true/false questions after English story listening provides a simple yet powerful strategy to improve your listening comprehension. The combination of audio stories and targeted questions enhances your English hearing practice, allowing you to engage with the language actively and effectively. Whether you're practicing for an exam or simply looking to boost your daily conversation skills, this approach ensures you’re making steady progress toward mastering English listening.</p>
        </section>
      </div>
    </div>
  );
};

export default ListeningTrueFalse;
