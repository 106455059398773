import React, { FC } from 'react';
import './HowTo.scss';
import { Helmet } from 'react-helmet';

interface HowToProps {}

const HowTo: FC<HowToProps> = () => (
  <div className="HowTo" data-testid="HowTo">
    <Helmet>
      <title>Everyday English | How To</title>
    </Helmet>
    <div className='how-block'>
      <div>
        <h1>How to Use the App</h1>
        <p>
          Welcome to our app!
          The website is designed to help you learn important English skills more efficiently and make the process enjoyable. On the Home page, you will find various learning options, such as Words, Quizzes, and more. Each option represents a unique tool with different interactive features. At the bottom of the page, you’ll find detailed descriptions of each tool.

          If you have any questions about the descriptions, feel free to reach out to us using the method that’s most convenient for you.

          In the future, additional tools and features will be added to the main page to further enhance your learning experience.
        </p>
        <p><strong className='fs-4'>Most Effective Ways to Use the App</strong></p>
        <p>
          Our website offers three exciting tools to enhance your English skills: Quiz, Words, and Irregular Verbs. 
          Each tool is designed to target specific areas of learning, making the process both engaging and effective. 
          Below, we’ll guide you on the best ways to use these features and provide practical scenarios to illustrate 
          their benefits.
        </p>
        <p><strong className='fs-4'>1. Quiz</strong></p>
        <ul>
          <li>Start your day by taking the 10-question grammar quiz. These quizzes are updated daily (with AI), ensuring fresh and relevant content.</li>
          <li>Focus on understanding the explanations provided for incorrect answers to strengthen your grasp of grammar rules.</li>
          <li>Set a goal to track your progress over time.</li>
        </ul>
        <p><strong className='fs-4'>2. Words (Swipe and Learn New Vocabulary)</strong></p>
        <ul>
          <li>Use the swipe feature to discover new words each day. Each word comes with its meaning and pronunciation guide.</li>
          <li>Create a habit of reviewing 5–10 new words daily.</li>
          <li>Challenge yourself by using the words in sentences or conversations to reinforce learning.</li>
        </ul>
        <p><strong className='fs-4'>3. Irregular Verbs (Fill in the Other Forms)</strong></p>
        <ul>
          <li>Spin to generate a base form of an irregular verb.</li>
          <li>Test yourself by filling in the past simple and past participle forms.</li>
          <li>Use the “Check” button to instantly see if your answers are correct.</li>
          <li>Repeat the process daily to memorize all common irregular verbs.</li>
        </ul>
        <p>
          <strong>Start with 1 Quiz:</strong> Spend 10 minutes completing the daily grammar quiz. With 10 questions each day, 
          you'll answer 100 questions in 10 days and 200 questions in 20 days, steadily improving your understanding 
          of English grammar.
        </p>
        <p>
          <strong>Swipe Through Words:</strong> Spend another 10 minutes discovering and learning new words. If you learn 10 words a day, 
          you'll master 100 words in just 10 days and 200 words in 20 days, significantly expanding your vocabulary.
        </p>
        <p>
          <strong>Practice Irregular Verbs:</strong> Finally, spend 10 minutes practicing irregular verbs. By focusing on 10 verbs a day, 
          you'll master 100 irregular verbs in 10 days and 200 verbs in 20 days, making verb usage more natural in conversation 
          and writing.
        </p>
      </div>
    </div>
  </div>
);

export default HowTo;
