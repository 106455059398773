import React, { FC } from 'react';
import './GeneralTips.scss';

interface GeneralTipsProps {}

const GeneralTips: FC<GeneralTipsProps> = () => (
  <div className="GeneralTips" data-testid="GeneralTips">
    GeneralTips Component
  </div>
);

export default GeneralTips;
